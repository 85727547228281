<!-- 教学素材 -- 学科知识点 -->
<template>
	<div class="teacher">
		<list-template
			:current-page="page"
			:loading="loading"
			:search-config="searchConfig"
			:table-config="tableConfig"
			:table-data="tableData"
			:total="total"
			@onAdd="$router.push('./add')"
			@onChangePage="changeCurrentPage"
			@onReset="search = null"
			@onSearch="onSearch"
			@onHandle="onHande"
		></list-template>
		<h-dialog :historyDialogShow="historyDialogShow" :historyList="historyList" @close="historyDialogShow = false"></h-dialog>
	</div>
</template>

<script>
import {mapState} from "vuex";
import {parseData} from "utils/l-tools";

export default {
	components: {History},
	_config: {"route": {"path": "list", "meta": {"title": "列表", "keepAlive": true}}},
	data() {
		return {
			loading: true,
			searchConfig: [
				{
					prop: "subject_id",
					tag: "select",
					placeholder: "筛选所属学科",
					options: [],
					label: "subject_name",
					value: "id",
				}, {
					prop: "apply_type",
					tag: "select",
					placeholder: "筛选应用学段",
					options: [],
					label: "name",
					value: "id",
				},
			],
			tableConfig: [
				{
					prop: "subject_name",
					label: "所属学科",
				},
				{
					prop: "apply_type",
					label: "应用学段",
				},
				{
					prop: "knowledge_num",
					label: "知识点总数",
				},
				{
					prop: "video_num",
					label: "关联视频总数",
				},
				{
					handle: true,
					label: "操作",
					render: (h, params) => {
					},
				},
			],
			search: null,
			total: 0,
			// 表格中的数据
			tableData: [],
			historyDialogShow: false,
			historyList: [],
		};
	},
	computed: {
		...mapState(["page"]),
	},
	mounted() {
		this.$store.commit("setPage", 1);
		this.getData();
		this.$api2.get("api/video/search-knowledge").then(res => {
			this.searchConfig[1].options = parseData(res);
		});
		this.$api2.get("api/common/subject?level=1").then(res => {
			this.searchConfig[0].options = parseData(res);
		});
	},
	activated() {
		this.getData();
	},
	methods: {
		// 分页被更改
		changeCurrentPage(e) {
			this.$store.commit("setPage", e);
			this.getData();
		},

		getData() {
			let {search, page} = this;
			this.loading = true;
			this.$_axios2.get("api/video/list-knowledge", {params: {...search, page}}).then(res => {
				let {data} = res.data;
				this.tableData = data.list;
				this.total = data.page.total;
			}).finally(() => this.loading = false);
		},
		// 搜索按钮被点击
		onSearch(val) {
			this.$store.commit("setPage", 1);
			this.search = val;
			this.getData();
		},
		onHande(row, val) {
			if (val.type === "edit")
				this.$router.push(`./edit?id=${row.id}`);
			else
				this.$api2.get(`api/common/public/get-opt-history?tbl_name=video_knowledge_basic&tbl_id=${row.id}`).then((res) => {
					this.historyList = parseData(res).data.list;
					this.historyDialogShow = true;
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.list {
	padding: 24rem 0 0 32rem;

	.search {
		.el-input,
		.el-select {
			width: 240rem;
			margin-right: 24rem;
		}
	}

	.pagination {
		margin-top: 50rem;
	}
}
</style>
